import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import styles from './navbar.module.scss';
import profileImage from '../../../assets/profile.png';
import { GiHamburgerMenu } from "react-icons/gi";
import { setHam } from '../../../app/Slice/hamSlice';
import { setAuth, setAuthBoolean } from '../../../app/Slice/authSlice';
import logo from '../../../assets/company.png';


export default function Navbar(){
    const accountName = useAppSelector((state) => state.auth)
    const hamStatus = useAppSelector((state)=>state.ham);
    const dispatch = useAppDispatch()
    return(
        <nav>
            { accountName.auth ? <div onClick={()=>{dispatch(setHam(!hamStatus))}} className={styles.ham}><GiHamburgerMenu color='white' size={24} /></div> : <></>}
            {accountName.accountName==='Finvtech' ? <img style={{height:"2.5rem"}} src={logo}/> :  <p  className={styles.left}>{accountName.accountName}</p>}
            {
                accountName.auth ?
                <div  className={styles.right}>
                <div className={styles.account}>
                    <p className={styles.name}>{accountName.userName}</p>
                    <p className={styles.plan}>Base Plan</p>
                </div>
                <div className={styles.image}>
                    <img src={profileImage} />
                </div>
            </div>
            :
            <div></div>
            }
            <div className={styles.rightMobile}/>
        </nav>
    )
}