import { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch} from '../../../app/hooks';
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { RiRobot3Line } from "react-icons/ri";
import { GoLink } from "react-icons/go";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FiSettings } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import styles from './sidebar.module.scss';
import { setRoute } from '../../../app/Slice/routeSlice';
import { hide, setHam } from '../../../app/Slice/hamSlice';
import { Button } from '@tremor/react';
import { setAccount, setAuth, setAuthBoolean, setUserName } from '../../../app/Slice/authSlice';



const nav =[
    "Home","Bots","Brokers","Transactions","Settings"
]
export default function Sidebar() {
    const [active, setActive]=useState<string>('Home')
    const [ham,setHam]=useState<boolean>(false);
    const route =useAppSelector((state:any)=>state.route);
    const dispatch = useAppDispatch();
    const hamStatus = useAppSelector((state)=>state.ham);
    const auth= useAppSelector((state) => state.auth);
    const [statusReload, setStatusReload]=useState(false);

/*     useEffect(() => {
        console.log('changing')
        if (window.innerWidth <= 1000) {
            setHam(true);
        } else {
            setHam(false);
        }
    }, [ham]); */

    function setLogout(){
        dispatch(setUserName('Unknown'));
        dispatch(setAccount("Login"));
        dispatch(setAuth(''));
        setStatusReload(true);
    }

    useEffect(()=>{
        console.log(auth);
        statusReload && dispatch(setAuthBoolean(false));
    },[statusReload])
    useEffect(()=>{
        route==='' && dispatch(setRoute('Home'));
    },[])
    return (
        <div className={styles.sidebar}>
            <div className={styles.sidebarPc}>
                <ul>
                    <Link to="/"><li onClick={()=>{dispatch(setRoute('Home'))}} style={route==='Home' ? {color:"#007AFF", cursor:"pointer"}:{cursor:"pointer"}} ><AiOutlineHome  /> Home</li></Link> 
                    <Link to='/bots'><li style={route==='Bots' ? {color:"#007AFF", cursor:"pointer"}:{cursor:"pointer"}} onClick={()=>{dispatch(setRoute('Bots'))}}><RiRobot3Line /> Bots</li></Link>
                    <Link to='/broker'><li style={route==='Brokers' ? {color:"#007AFF", cursor:"pointer"}:{cursor:"pointer"}} onClick={()=>{dispatch(setRoute('Brokers'))}}><GoLink /> Brokers</li></Link>
                    <Link to='/transaction'><li style={route==='Transaction' ? {color:"#007AFF", cursor:"pointer"}:{cursor:"pointer"}} onClick={()=>{dispatch(setRoute('Transaction'))}}><IoDocumentTextOutline /> Transaction</li></Link>
                    <Link to='/settings'><li style={route==='Settings' ? {color:"#007AFF", cursor:"pointer"}:{cursor:"pointer"}} onClick={()=>{dispatch(setRoute('Settings'))}}><FiSettings /> Settings</li></Link>
                </ul>
                <Button onClick={()=>{setLogout();}} color='red' variant='secondary' style={{marginBottom:"4rem"}}>Logout</Button>
            </div>
            <div className={ hamStatus ? styles.sideBarMobile : styles.hide}>
                <ul>
                    <Link to="/"><li onClick={()=>{dispatch(setRoute('Home')); dispatch(hide())}} style={route==='Home' ? {color:"#007AFF", cursor:"pointer"}:{cursor:"pointer"}} ><AiOutlineHome  /> Home</li></Link> 
                    <Link to='/bots'><li style={route==='Bots' ? {color:"#007AFF", cursor:"pointer"}:{cursor:"pointer"}} onClick={()=>{dispatch(setRoute('Bots')); dispatch(hide())}}><RiRobot3Line /> Bots</li></Link>
                    <Link to='/broker'><li style={route==='Brokers' ? {color:"#007AFF", cursor:"pointer"}:{cursor:"pointer"}} onClick={()=>{dispatch(setRoute('Brokers'));dispatch(hide())}}><GoLink /> Brokers</li></Link>
                    <Link to='/transaction'><li style={route==='Transaction' ? {color:"#007AFF", cursor:"pointer"}:{cursor:"pointer"}} onClick={()=>{dispatch(setRoute('Transaction')); dispatch(hide())}}><IoDocumentTextOutline /> Transaction</li></Link>
                    <Link to='/settings'><li style={route==='Settings' ? {color:"#007AFF", cursor:"pointer"}:{cursor:"pointer"}} onClick={()=>{dispatch(setRoute('Settings'))}}><FiSettings /> Settings</li></Link>
                </ul>
                <Button onClick={()=>{
                    setLogout();
                    }} color='red' variant='secondary' style={{marginBottom:"0rem"}}>Logout</Button>
            </div>
        </div>
    )
}
