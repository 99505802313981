import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styles from './App.module.scss';

import { useAppSelector, useAppDispatch } from './app/hooks';
import Control from './global/Componnets/Control/control';
import Homepage from './Pages/homepage/homepage';
import Bots from './Pages/bots/bots';
import Broker from './Pages/broker/broker';
import Transaction from './Pages/transaction/transactio';
import Settings from './Pages/settings/settings';
import Login from './Pages/login/login';
import Navbar from './global/Componnets/Navbar/navbar';
import Redirect_Upstrox from './Pages/redirect-pages/redirectUpstox';
import Redirect_Fyer from './Pages/redirect-pages/redirectFyers';
import { Button, Card, Title } from '@tremor/react';
import axios from 'axios';
import { baseUrl } from './app/shared/baseUrl';
import { setAuth, setAuthBoolean, setTerms } from './app/Slice/authSlice';
import { useState } from 'react';



function App() {
  const dispatch=useAppDispatch();
  const auth = useAppSelector((state) => state.auth)
  const [checked, setChecked]=useState<boolean>(false)
  const termsData = [
    `All subscription fees paid to '${auth.accountName}' is Non refundable.`,
    "We do not provide trading tips not we are investment adviser.",
    "Our service is solely restricted to automated trading application development, deployment and maintenance.",
    "All algorithms are based on backtested data but we do not provide any guarantee for their performance in future.",
    "The algorithm running in an automated system is agreed with the user prior deployment and we do not take any liability for any loss generated by the same.",
    `Past performance of advise/strategy/model does not indicate the future performance of any current or future strategy/model or advise by '${auth.accountName}' Trades and actual returns may differ significantly from that depicted herein due to various factors including but not limited to impact costs, expense charged, timing of entry/exit, timing of additional flows/redemptions, individual client mandates, specific portfolio construction characteristics etc.`,
    `There is no assurance or guarantee that the objectives of any strategy/model or advice provided by '${auth.accountName}' Trades will be achieved.`,
    `'${auth.accountName} Trades or any of its partner/s or principal officer/employees do not assure/give guarantee for any return on the investment in strategies/models/advice given to the Investor.`,
    `The value of investment can go up/down depending on factors & forces affecting securities markets '${auth.accountName}' Trades or its associates are not liable or responsible for any loss or shortfall arising from operations and affected by the market condition.`
  ]
  
  async function setTermsDetails() {
    try {
      const response = await axios.patch(
        `${baseUrl}/api/customer/update-tnc-status?tnc_status=true`,
        {},
        {
          headers: {
            "Authorization": `Bearer ${auth.authCode}`,
            "Content-type": "application/json",
          }
        }
      );
      if (response.status === 200) {
        dispatch(setTerms(true));
        console.log(response.data.tnc_agreed);
      }
    } catch (error) {
      console.error("Error updating terms and conditions status:", error);
    }
  }

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  if(auth.auth){
    if(!auth.terms){
      return(
        <div className={styles.app}>
          <Navbar />
          <Card className={styles.terms}>
            <Card className={styles.head}>
              <Title className={styles.title}>Terms and Conditions</Title>
            </Card>
            <Card className={styles.details}>
              {termsData.map((item, index)=>(
                  <div key={index} style={{display:"flex", gap:"5px",marginBottom:"4px"}}>
                    <p >{index+1}.</p>
                    <p key={item+1}>{item}</p>
                  </div>
                ))}
                <div style={{display:"flex", textAlign:'start',marginTop:"2rem", alignItems:'center', gap:'10px'}}>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
                  <Title style={{marginLeft:"1rem"}}>I confirm that I have read and accept the terms and conditions and privacy policy.</Title>
                </div>
                <div style={{width:"100%", display:"flex",marginTop:"2rem", justifyContent:"end"}}><Button color='gray' variant='secondary' onClick={()=>{setTermsDetails()}} disabled={!checked}>Agree</Button></div>
            </Card>
          </Card>
        </div>
      )
    }
    else{
      return (
        <div>
          <Control />
            <Routes>
              <Route path='/' element={<Homepage/>} />
              <Route path='/bots' element={<Bots/>} />
              <Route path='/broker' element={<Broker/>} />
              <Route path='/transaction' element={<Transaction />} />
              <Route path='/settings' element={<Settings />} />
              <Route path='/upstox' element={<Redirect_Upstrox />} />
              <Route path='/fyers' element={<Redirect_Fyer />} />
            </Routes>
        </div>
      );
    }
  }
  else{
    return(
      <div>
        <Navbar />
        <Login />
      </div>
    )

  }

}

export default App;
