import React, { useState } from 'react'
import styles from './login.module.scss'
import { Button, Card, TextInput, Title } from '@tremor/react'
import { FaArrowRight } from "react-icons/fa";
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setAccount, setAuth, setAuthBoolean, setTerms, setUserName } from '../../app/Slice/authSlice';
import { baseUrl } from '../../app/shared/baseUrl';
import toast, { Toaster } from 'react-hot-toast';
import { setRoute } from '../../app/Slice/routeSlice';
import { useNavigate } from 'react-router-dom';

interface Login{
    email:string,
    password:string
  }

function Login() {
    const [loginData, setLoginData]=useState<Login>({
        email:'',
        password:''
    });
    const auth= useAppSelector((state) => state.auth)

    const loginFailed = () => toast.error(`Login Failed!`)

    const dispatch=useAppDispatch();
    const navigate = useNavigate();
    async function getDetails(){
        console.log('called');
        try {
            const response = await axios.get(`${baseUrl}/api/tenant/get-comany-info`);
            console.log(response.data);
            if (response.status === 200) {
                console.log(response.data.company_name);
                dispatch(setAccount(response.data.company_name));
            }
        } catch (error: any) {
            console.log("the response is "+error);
            dispatch(setAccount("UnKnown"))
        }
    }



    async function login(logindata1:Login){
        try {
            const response = await axios.post(`${baseUrl}/api/customer/login`,logindata1);
            if(response.status===200 || response.status===201){
                console.log(response.data)
                dispatch(setAuth(response.data.acess_token));
                dispatch(setTerms(response.data.tnc_agreed));
                console.log(response.data.tnc_agreed);
                dispatch(setAuthBoolean(true));
                dispatch(setRoute('Home'))
                navigate('/')
                getDetails();
            }
        } catch (error) {
            loginFailed();
            dispatch(setAuth(''));
            dispatch(setAuthBoolean(false));
            dispatch(setRoute(''))
        }
    }

    return (
        <div className={styles.login}>
            <Toaster position='top-right' />
            <Card className={styles.inputCard}>
            <div className={styles.mobile}></div>
                <Title className={styles.logo}>Login</Title>
                <div className={styles.inputs}>
                    <div className={styles.input}>
                        <Title className={styles.text1}>Username</Title>
                        <TextInput className={styles.box} onValueChange={(value)=>{setLoginData(prev=>(
                            {
                                ...prev,
                                email:value
                            }
                        ))}} value={loginData?.email} style={{background:"#F3F4F6"}} placeholder='' />
                    </div>
                    <div className={styles.input}>
                        <Title className={styles.text1}>Password</Title>
                        <TextInput className={styles.box} onValueChange={(value)=>{setLoginData(prev=>(
                            {
                                ...prev,
                                password:value
                            }
                        ))}} value={loginData?.password} style={{background:"#F3F4F6"}} placeholder='' />
                    </div>
                </div>
                <div onClick={()=>{login(loginData)}} className={styles.button}><div style={{width:"1.7rem"}}/><Title className={styles.text}>Login</Title><FaArrowRight /></div>
            </Card>
        </div>
    )
}

export default Login