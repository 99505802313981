import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface authState{
    authCode:string
    accountName:string
    auth:boolean
    terms:boolean
    userName:string
}

const initialState : authState ={
    authCode:"",
    accountName:"Login",
    auth:false,
    terms:true,
    userName:"User"
}

export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        setAuth:(state, action: PayloadAction<string>)=>{
            state.authCode=action.payload;
        },
        setAccount:(state, action: PayloadAction<string>)=>{
            state.accountName=action.payload;
        },
        setAuthBoolean:(state, action: PayloadAction<boolean>)=>{
            state.auth=action.payload;
        },
        setTerms:(state, action: PayloadAction<boolean>)=>{
            state.terms=action.payload;
        },
        setUserName:(state, action: PayloadAction<string>)=>{
            state.userName=action.payload;
        },
    }
})

export const { setAuth , setAccount, setAuthBoolean, setTerms, setUserName} = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer